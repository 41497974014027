import AdminBar from './admin-bar';
import './sass/admin-bar.scss';

declare global {
    interface Window {
        bcAdminBar: typeof AdminBar
    }
}

window.bcAdminBar = AdminBar;
