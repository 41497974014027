import { ContextualMatch, PermissionsType } from './admin-bar';
import adminBrush from './img/admin-brush.svg';
import adminClose from './img/admin-close.svg';
import adminEdit from './img/admin-edit.svg';
import adminLink from './img/admin-link.svg';
import adminToolTip from './img/admin-tool-tip.svg';
import logo from './img/logo-small.svg';

interface LangType {
    admin: {
        [key: string]: string;
    }
}

interface AdminBarHtmlProps {
    channelId: number;
    contextualMatch: {
        type: string;
        url: string;
    } | undefined;
    isMaintenanceMode: boolean;
    lang: LangType;
    password: string;
    secureBaseUrl: string;
    url: string;
    permissions: PermissionsType;
    isMsfOn: boolean;
    franchisePermissions: PermissionsType;
}

const renderDisabledButton = (className: string, hoverTitle: string, linkTitle: string) =>
    `<button class="${className} disabled" title="${hoverTitle}">
        <span class="svg-icon svg-baseline">
            <img src="${adminBrush}" alt="Disabled brush to ${linkTitle} "/>
        </span><span class="adminBar-large"> ${linkTitle}</span>
    </button>`;

const renderContextualLink = (
    contextualMenu: ContextualMatch | undefined,
    secureBaseUrl: string,
    permissions: PermissionsType,
    lang: LangType,
    channelId: number,
    isMsfOn: boolean,
    franchisePermissions: PermissionsType
): string => {
    const { type, url } = contextualMenu || {};
    const hasPermission: boolean = type ? permissions[type] : false;

    if (!type || !url) {
      return '';
    }

    const categoryUrl = isMsfOn ?
        url.replace('/edit', '')
           .replace('categories', `categories/${channelId}/edit`)
        : url;

    const {
        edit_category_information: editCategoryInformation,
        edit_product_information: editProductInformation,
        edit_category_permission_required: editCategoryPermissionRequired,
        edit_product_permission_required: editProductPermissionRequired,
    } = lang.admin;

    const editText = type === 'category' ? editCategoryInformation : editProductInformation;
    const disabledText = type === 'category' ? editCategoryPermissionRequired : editProductPermissionRequired;
    const finalUrl = type === 'category' ? categoryUrl : url;

    const contextualLink = `
        <a class="contextual link" href='${secureBaseUrl}${finalUrl}' target="_blank">
            <span class="svg-icon svg-baseline">
                <img src="${adminEdit}" alt="Enabled brush for ${type} edit"/>
            </span><span class="adminBar-large"> ${editText}</span></a>`;

    /* eslint-disable @typescript-eslint/dot-notation */
    if (franchisePermissions['Edit_Products'] !== undefined || franchisePermissions['Edit_Categories'] !== undefined) {
        const editCategories = franchisePermissions['Edit_Categories'] ? contextualLink : '';
        const editProducts = franchisePermissions['Edit_Products'] ? contextualLink : '';

        return type === 'category' ? editCategories : editProducts;
    }

    return hasPermission ? contextualLink : renderDisabledButton(type, `${disabledText}`, editText);
};

const renderPageBuilderLink = (
    permissions: PermissionsType,
    secureBaseUrl: string,
    url: string,
    channelId: number,
    lang: LangType
) => {
    const { pageBuilder } = permissions;
    const {
        admin: {
            page_builder_link: pageBuilderLink,
            page_builder_permission_required: pageBuilderPermissionsRequired
        }
    } = lang;

    const decodedUrl = decodeURIComponent(url);

    return pageBuilder ?
        `<a 
            class="page-builder link" 
            href="${secureBaseUrl}/manage/page-builder?channelId=${channelId}${decodedUrl ? `&redirectIframeUrl=${decodedUrl}` : ''}" 
            target="_blank">
            <span class="svg-icon svg-baseline">
                <img src="${adminBrush}" alt="Enabled brush for page builder edit."/>
            </span><span class="adminBar-large"> ${pageBuilderLink}</span>
        </a>` :
        `${renderDisabledButton('page-builder', pageBuilderPermissionsRequired, pageBuilderLink)}`;
};

const adminBarHtml = ({
    channelId,
    contextualMatch,
    isMaintenanceMode,
    lang,
    password,
    secureBaseUrl,
    url,
    permissions,
    isMsfOn,
    franchisePermissions
}: AdminBarHtmlProps): string => `
    <div class="adminBar-logo">
        <a href="${secureBaseUrl}/manage/dashboard">
            <img src="${logo}" width="45" alt="image of BC Logo"/>
        </a>
    </div>
        <div class="adminBar-content">
        ${password ?
        `<div class="adminBar-private">
            <span class="preview">${lang.admin.prelaunch_header} <strong>${password}</strong></span>
        </div>` : ''}
        ${isMaintenanceMode ?
        `<div class="adminBar-private">
            <span>${lang.admin.maintenance_header}</span>
            <span class="svg-icon svg-baseline adminBar-large tooltip">
                <img src="${adminToolTip}" alt="Icon for maintenance tooltip"/>
                <span class="tooltiptext tooltip-bottom">${lang.admin.maintenance_tooltip}</span>
            </span>
            <a href="?showStore=no" class="adminBar-large">${lang.admin.maintenance_showstore_link} 
                <span class="svg-icon svg-baseline">
                    <img src="${adminLink}" alt="Icon for maintenance link"/>
                </span>
            </a>
         </div>` : ''}
         <div class="adminBar-links">
            ${renderContextualLink(contextualMatch, secureBaseUrl, permissions, lang, channelId, isMsfOn, franchisePermissions)}
            ${renderPageBuilderLink(permissions, secureBaseUrl, url, channelId, lang)}
         </div>
         <div class="adminBar-close" id="close-admin-bar">
            <span><img src="${adminClose}" width="14" alt="Close Admin Bar"/></span>
         </div>
    </div>`;

export default adminBarHtml;
