/* eslint-disable  @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */
import adminBarHtml from './html-const';
import defaultTranslations from './lang/en.json';

const attachToDocumentBody = (adminBarElm: HTMLDivElement) => {
    const body: HTMLBodyElement | null = document.querySelector('body');
    const closeButton: HTMLDivElement | null = adminBarElm.querySelector('#close-admin-bar');
    const paddedElements = ['script', '.skip-to-main-link'];

    body?.classList.add('hasAdminBar');
    body?.insertBefore(adminBarElm, body.firstChild);

    body?.querySelectorAll<HTMLElement>(`.adminBar.storefront ~ *:not(${paddedElements.toString()})`)
        .forEach(elm => {
            elm.style.marginTop = `${parseInt(window.getComputedStyle(elm).marginTop, 10) + 46}px`;
        });

    closeButton?.addEventListener('click', () => {
       body?.classList.remove('hasAdminBar');
       body?.querySelectorAll('.adminBar').forEach(adminBar => {
           body?.querySelectorAll<HTMLElement>(`.adminBar.storefront ~ *:not(${paddedElements.toString()})`)
            .forEach(elm => {
                elm.style.marginTop = `${parseInt(window.getComputedStyle(elm).marginTop, 10) - 46}px`;
            });

           adminBar.remove();
       });

       document.cookie = 'ADMIN_BAR=0; path=/';
    });
};
export interface ContextualMatch {
    type: string;
    url: string;
}

export interface PermissionsType {
    [key: string]: boolean;
}

export const DEFAULT_PERMISSIONS: PermissionsType = {
    pageBuilder: true,
    product: true,
    category: true,
};

const createAdminBar = (): HTMLDivElement => {
    const elm = document.createElement('div');
    elm.classList.add('adminBar');

    return elm;
};

const AdminBar = (
  secureBaseUrl: string,
  channelId = 1,
  maintenanceMode: string,
  productId: string,
  categoryId: string,
  adminPermissions: string = JSON.stringify(DEFAULT_PERMISSIONS),
  translations: string = JSON.stringify(defaultTranslations),
  isMsfOn = false,
  franchisedPermissions: string = JSON.stringify([])
): void => {
    const { header, password } = JSON.parse(maintenanceMode) || {};
    const isMaintenanceMode = header && !password;
    const permissions: PermissionsType = JSON.parse(adminPermissions);
    const franchisePermissions: PermissionsType = JSON.parse(franchisedPermissions);

    const lang = JSON.parse(translations);

    const isInIframe = () => {
        try {
            return window.location !== window.parent.location;
        } catch (e) {
            return true;
        }
    };

    const getContextualMatch = (_productId: string, _categoryId: string): ContextualMatch | undefined => {
        if (_productId && parseInt(_productId, 10)) {
            return {
                type: 'product',
                url: `/manage/products/${_productId}/edit`,
            };
        } else if (_categoryId && parseInt(_categoryId, 10)) {
            return {
                type: 'category',
                url: `/manage/products/categories/${_categoryId}/edit`,
            };
        }
    };

    const doesNotHaveAdminCookie = () => document.cookie.indexOf('ADMIN_BAR=1') === -1;

    // Set admin cookie if appropriate
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    if (window.URLSearchParams && window.URL && (new URL(document.location as any)).searchParams.get('ctk')) {
        document.cookie = 'ADMIN_BAR=1; path=/';
    }

    // Return if isInIframe is true (inside page builder) or if there is no admin cookie
    if (doesNotHaveAdminCookie() || isInIframe()) {
        return;
    }

    const contextualMatch = getContextualMatch(productId, categoryId);
    const url = encodeURIComponent(
        (new URL(window.location.href).pathname + window.location.search)
            .replace(/^\/|\/$/g, '')
            .replace(/[&|?]?ctk=[a-z|0-9|-]+/g, '')
    );

    const adminBarElm: HTMLDivElement = createAdminBar();
    adminBarElm.classList.add('storefront', 'adminBar');

    adminBarElm.insertAdjacentHTML('afterbegin', adminBarHtml({
        channelId,
        contextualMatch,
        isMaintenanceMode,
        lang,
        password,
        secureBaseUrl,
        url,
        permissions,
        isMsfOn,
        franchisePermissions
    }));

    attachToDocumentBody(adminBarElm);
};

export default AdminBar;
